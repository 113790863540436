export type LocalazyLanguage = {
    language: string;
    region: string;
    script: string;
    isRtl: boolean;
    localizedName: string;
    name: string;
    pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};
export type LocalazyFile = {
    cdnHash: string;
    file: string;
    path: string;
    library: string;
    module: string;
    buildType: string;
    productFlavors: string[];
    cdnFiles: { [lang:string]: string };
};
export type LocalazyMetadata = {
    projectUrl: string;
    baseLocale: string;
    languages: LocalazyLanguage[];
    files: LocalazyFile[];
};
     
const localazyMetadata: LocalazyMetadata = {
  projectUrl: "https://localazy.com/p/contentkalender",
  baseLocale: "nl",
  languages: [
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fr",
      region: "",
      script: "",
      isRtl: false,
      name: "French",
      localizedName: "Français",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "nl",
      region: "",
      script: "",
      isRtl: false,
      name: "Dutch",
      localizedName: "Nederlands",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "nl",
      region: "BE",
      script: "",
      isRtl: false,
      name: "Flemish",
      localizedName: "Nederlands (België)",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    }
  ],
  files: [
    {
      cdnHash: "267a0e5acdfa430e398bc5a0fd000fdf47bc0368",
      file: "backend.json",
      path: "",
      library: "",
      module: "",
      buildType: "",
      productFlavors: [],
      cdnFiles: {
        "de": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/267a0e5acdfa430e398bc5a0fd000fdf47bc0368/de/backend.json",
        "en": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/267a0e5acdfa430e398bc5a0fd000fdf47bc0368/en/backend.json",
        "fr": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/267a0e5acdfa430e398bc5a0fd000fdf47bc0368/fr/backend.json",
        "nl": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/267a0e5acdfa430e398bc5a0fd000fdf47bc0368/nl/backend.json",
        "nl_BE": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/267a0e5acdfa430e398bc5a0fd000fdf47bc0368/nl-BE/backend.json"
      }
    },
    {
      cdnHash: "ab280978953a88a971bdca0327e1eb6eaa6bfa4e",
      file: "webapp.json",
      path: "",
      library: "",
      module: "",
      buildType: "",
      productFlavors: [],
      cdnFiles: {
        "de": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/ab280978953a88a971bdca0327e1eb6eaa6bfa4e/de/webapp.json",
        "en": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/ab280978953a88a971bdca0327e1eb6eaa6bfa4e/en/webapp.json",
        "fr": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/ab280978953a88a971bdca0327e1eb6eaa6bfa4e/fr/webapp.json",
        "nl": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/ab280978953a88a971bdca0327e1eb6eaa6bfa4e/nl/webapp.json",
        "nl_BE": "https://delivery.localazy.com/_a75771114607649267461277834e/_e0/ab280978953a88a971bdca0327e1eb6eaa6bfa4e/nl-BE/webapp.json"
      }
    }
  ]
};

export default localazyMetadata;
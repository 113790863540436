<script setup lang="ts">
    import { ref, onMounted, computed, watch } from 'vue'
    import { useAuthStore } from '@stores/authStore'

    import { useI18n } from 'vue-i18n'
    const { t } = useI18n()

    const authStore = useAuthStore()
    const showToast = ref(false)
    const shareableLink = computed(() => authStore.me.isDepartmentUser)

    watch(
        shareableLink,
        (newValue) => {
            if (shareableLink.value === true || newValue === true) {
                showToast.value = true
            }
        },
        { immediate: true }
    )

    onMounted(() => {
        if (shareableLink.value) {
            setTimeout(() => {
                showToast.value = true
            }, 500)
        }
    })
</script>

<template>
    <v-container class="nopad">
        <div
            v-if="showToast"
            class="toast d-flex rounded-lg pa-4 align-center justify-space-between text-white"
            :style="{ zIndex: '99999', maxWidth: '650px', minHeight: '60px', backgroundColor: 'var(--color-warning)' }"
        >
            <div class="rounded-xl d-flex align-center justify-center" :style="{ minWidth: '22px', height: '22px', backgroundColor: 'rgba(255, 255, 255, 0.7)' }">
                <i class="fa-solid fa-exclamation thick" :style="{ color: '#ffc00a' }"></i>
            </div>
            <p class="px-6 text-left text-subtitle-1">{{ t('shareableLink.message') }}</p>
            <v-btn variant="plain" class="p-0" :style="{ minWidth: 'fit-content' }" @click="showToast = false"><i class="fa-solid fa-xmark text-h6"></i></v-btn>
        </div>
    </v-container>
</template>

<style lang="scss" scoped>
    .nopad {
        padding: 0 !important;
    }
    .thick {
        -webkit-text-stroke: 1px var(--color-warning);
        font-size: 16px;
        font-weight: bold;
        padding-top: 1px;
    }
    .toast {
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        animation: slideIn 0.5s ease-out;
    }

    @keyframes slideIn {
        0% {
            bottom: -100px;
            opacity: 0;
        }
        100% {
            bottom: 20px;
            opacity: 1;
        }
    }
</style>

import { ref } from 'vue'
import { getAllPermissions } from '@/api/auth'
import { PermissionType, PermissionAction, UserFlag } from '@/api/auth'
import type { FeatureFlag } from '@/api/organisation'

async function refreshPermissions() {
    try {
        const res = await getAllPermissions()
        permissionTypes.value = res.permissions
        permissionFlags.value = res.authorization.featureFlags
    } catch (e) {}
}

async function getPermissionTypes() {
    if (!permissionTypes.value) {
        await refreshPermissions()
    }
    return permissionTypes
}
async function getPermissionFlags() {
    if (!permissionTypes.value) {
        await refreshPermissions()
    }
    return permissionFlags
}

const permissionTypes = ref<
    {
        [key in PermissionType]: PermissionAction[]
    } & { flags: UserFlag[] }
>()

const permissionFlags = ref<FeatureFlag[]>()

export default {
    getPermissionTypes,
    getPermissionFlags,
    permissionTypes,
    permissionFlags,
}

import { defineStore } from 'pinia'
import { computed, ref, watch, reactive, onMounted } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import {
    getAuth,
    getAuthPublic,
    getAuthSso,
    getBypass,
    getMe,
    apiUpdateAuth,
    apiGetLanguage,
    apiGetCountry,
    type Me,
    type AuthLoginPublic,
    type CreateAuthBody,
    // type PermissionType,
    // type PermissionAction,
    // type PermissionFlag,
    // type UserFlag,
    type LanguageItem,
    type CountryItem,
    getAllPermissions,
    // type Check,
    apiGetCheck,
    type CreateAuthResetRequest,
    apiRequestPassword,
    type CreateAuthPasswordReset,
    apiResetPassword,
} from '@/api/auth'
import { apiGetBusinessrole, apiGetWebrole, type RoleItem, type WebroleItem } from '@/api/roles'

import thePermissions from '@/stores/permissions'
import router from '@/router'
import { useIdle, useTimestamp } from '@vueuse/core'

import handleAlerts from '@/utilities/handleMessages'
import refreshProgress from '@/utilities/refreshProgress' // loader utilities
import { initialRoute } from '@/utilities/directRouteManager'

export const useAuthStore = defineStore('auth', () => {
    const { lastActive } = useIdle(1000 * 5)
    const now = useTimestamp({ interval: 1000 })

    const idledFor = computed(() => Math.floor((now.value - lastActive.value) / 1000))

    // the store
    const defaultView = useLocalStorage('defaultView', ref('home'))

    const token = useLocalStorage('apiToken', ref(''))

    const me = useLocalStorage('me', ref<Me>({} as Me), { mergeDefaults: true })

    const meId = computed(() => {
        return me.value.id ? me.value.id : 0
    })

    const businessRoles = useLocalStorage('roles', ref<RoleItem[]>([]))
    const webRoles = useLocalStorage('webroles', ref<WebroleItem[]>([]))
    const language = useLocalStorage('language', ref<LanguageItem[]>())
    const country = useLocalStorage('country', ref<CountryItem[]>())
    const subscription = ref('')
    const impersonation = ref(false)

    async function refreshStore() {
        await refreshLanguage()
        await _refreshMe()
        await refreshBusinessrole()
        await refreshWebrole()
        await getPermissionTypes()
        await refreshCountry()
    }

    onMounted(() => {
        const _registerAuth = refreshProgress.registerRefreshFunction('auth')
        _registerAuth('language', refreshLanguage)
        _registerAuth('me', _refreshMe)
        _registerAuth('businessroles', refreshBusinessrole)
        _registerAuth('webroles', refreshWebrole)
        _registerAuth('country', refreshCountry)
        _registerAuth('permissions', getPermissionTypes)
    })

    async function _refreshMe() {
        if (!isLoggedIn.value) return
        const refreshMe = await getMe()
        if (refreshMe) {
            me.value = refreshMe
        }
    }

    async function refreshBusinessrole() {
        const businessResponse = await apiGetBusinessrole()
        if (!businessResponse.items || !businessResponse.items.length) return
        businessRoles.value = businessResponse.items as RoleItem[]
    }

    async function refreshWebrole() {
        const webRolesResponse = await apiGetWebrole()
        if (!webRolesResponse.items || !webRolesResponse.items.length) return
        webRoles.value = webRolesResponse.items as WebroleItem[]
    }

    async function refreshLanguage() {
        const languageResponse = await apiGetLanguage()
        if (!languageResponse || !languageResponse.items.length) return
        language.value = languageResponse.items as LanguageItem[]
    }

    async function refreshCountry() {
        const countryResponse = await apiGetCountry()
        if (!countryResponse || !countryResponse.items.length) return
        country.value = countryResponse.items as CountryItem[]
    }

    async function getPermissionTypes() {
        const res = await getAllPermissions()

        //STUB: MOCK data :remove testStrat and just use res.permissions and res.authorization

        thePermissions.permissionTypes.value = { ...res.permissions, collaboration: ['read', 'create', 'update', 'delete'] }
        // mock data
        // thePermissions.permissionTypes.value = {
        //     ...res.permissions,
        //     calendar: ['read'],
        // }

        thePermissions.permissionFlags.value = res.authorization.featureFlags
        // mock data
        // thePermissions.permissionFlags.value = {
        //     ...res.authorization,
        //     canUseKanban: false,
        // }
        subscription.value = res.subscription
        impersonation.value = res.impersonation
    }

    // const permissionTypes = ref<
    //     {
    //         [key in PermissionType]: PermissionAction[]
    //     } & { flags: UserFlag[] }
    // >()

    // const permissionFlags = ref<{
    //     [key in PermissionFlag]: boolean
    // }>()
    const permissionTypes = thePermissions.permissionTypes
    const permissionFlags = thePermissions.permissionFlags

    const mode: string = import.meta.env.VITE_ENVIRONMENT

    const isLoggedIn = computed(() => {
        return typeof token !== 'undefined' && token.value !== ''
    })

    const notificationPanel = reactive({
        show: false,
        navBtn: false,
        notifications: 0,
    })

    async function login(credentials: { email: string; password: string }) {
        const authResponse = await getAuth(credentials)
        if (authResponse.token) {
            token.value = authResponse.token
            // router.push({ name: 'home' })
        } else {
            return authResponse
        }
    }

    // department login / calendar login
    async function loginPublic(credentials: { password?: string | undefined | null; uuid: string | null }) {
        localStorageClear()
        const authResponse = (await getAuthPublic(credentials as any)) as AuthLoginPublic

        if (authResponse.token && authResponse.token !== '') {
            //token.value = authResponse.token
            router.push({ name: 'home' })
        }
        return authResponse
    }

    // sso login
    async function loginSso(uuid?: string) {
        const authResponse = (await getAuthSso(uuid as any)) as any
        return authResponse
    }

    // dev Bypass function
    async function bypass(id: number) {
        if (mode !== 'dev') return

        const bypassResponse = await getBypass(id)

        if (bypassResponse.token) {
            token.value = bypassResponse.token
            // router.replace({ name: defaultView.value, params: { period: 'month' } })
            router.replace({ name: 'home' })
        }
    }

    async function updateAuth(body: CreateAuthBody) {
        const authResponse = await apiUpdateAuth(body)
        if (authResponse) {
            me.value = authResponse as typeof authResponse & {
                created_at: number
            }
            return authResponse
        }
    }

    async function getCheck(body: string) {
        try {
            const checkResponse = await apiGetCheck(body)
            if (checkResponse) {
                return checkResponse
            }
        } catch (error: any) {
            console.error('Er is iets mis gegaan')
        }
    }

    async function requestPassword(body: CreateAuthResetRequest) {
        try {
            await apiRequestPassword(body)
        } catch (error: any) {
            handleAlerts.setError('Er is iets mis gegaan')
        }
    }

    async function resetPassword(body: CreateAuthPasswordReset) {
        try {
            const resetResponse = await apiResetPassword(body)
            if (resetResponse) {
                handleAlerts.setSuccess('Uw wachtwoord is verandert')
            }
        } catch (error: any) {
            handleAlerts.setError('Er is iets mis gegaan')
        }
    }

    function logout() {
        token.value = ''
        initialRoute.value = { name: defaultView.value, params: {} }
    }

    function localStorageClear() {
        //a contolled way of cleaning only the essential parts in localStorage
        const inLocalStorage: string[] = [
            'activeUsers',
            'contenttypes',
            'targets',
            'projects',
            'contenttypes',
            'communicationId',
            'me',
            'labels',
            'mediachannels',
            'roles',
            'webroles',
            'communicationstatus',
            'audiences',
            'calendars',
            'users',
            'workflows',
            'workflowTasks',
            'organisation',
        ]
        inLocalStorage.forEach((ls) => {
            if (localStorage.getItem(ls)) {
                localStorage.removeItem(ls)
            }
        })
    }
    function localStoragePollClear() {
        const inLocalStorage: string[] = ['calPoll', 'comPoll', 'refreshTimeout']
        inLocalStorage.forEach((ls) => {
            if (localStorage.getItem(ls)) {
                localStorage.removeItem(ls)
            }
        })
    }

    const { locale: i18nLocale } = useI18n({ useScope: 'global' })
    const FKconfig = ref<{ locale: string }>()

    const locale = useLocalStorage('locale', ref('nl'))

    watch(
        locale,
        (newLocale) => {
            i18nLocale.value = newLocale
            if (FKconfig.value) {
                //NOTE - Custom setting for nl_BE locale
                const formkitLocale = ['nl-BE', 'nl_BE'].includes(newLocale) ? 'nl' : newLocale
                FKconfig.value.locale = formkitLocale
            }
        },
        { immediate: true }
    )

    // check for token if so refetch Me
    watch(
        token,
        async (newToken, oldToken) => {
            if (newToken !== '' && (oldToken === undefined || oldToken === '')) {
                //NOTE @WS Waarom staat hier niet bij als token veranderd wordt?
                try {
                    me.value = await getMe()
                    getPermissionTypes()
                } catch (error: any) {
                    router.push({ name: 'login' })
                }
                //router.push({ name: defaultView.value })
            }
        },
        { immediate: true }
    )

    // check for idledFor
    watch(idledFor, (time) => {
        const currentRoute = router.currentRoute.value.name
        if (currentRoute === 'login') return
        // 15 minutes inactivity
        if (time === 5 * 60 * 60) {
            //if (time === 10) {
            alert('idled for ' + time + ' seconds you have been logged out')
            logout()
        }
    })

    // check for isLoggedIn
    watch(isLoggedIn, async (loggedIn) => {
        if (!loggedIn) {
            localStorageClear()
            localStoragePollClear()
            router.push({ name: 'login' })
        }
    })

    return {
        mode,
        token,
        FKconfig,
        login,
        loginPublic,
        loginSso,
        bypass,
        locale,
        logout,
        localStorageClear,
        isLoggedIn,
        defaultView,
        me,
        meId,
        businessRoles,
        webRoles,
        subscription,
        impersonation,
        language,
        country,
        notificationPanel,
        permissionTypes,
        permissionFlags,
        refreshStore,
        updateAuth,
        getCheck,
        requestPassword,
        resetPassword,
    }
})

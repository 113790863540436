import { defineStore } from 'pinia'
import { onMounted, ref, computed } from 'vue'

import {
    apiGetOrganisation,
    apiUpdateOrganisation,
    apiDeleteOrganisation,
    apiGetSubscription,
    apiGetSubscriptionTypes,
    apiGetCollaborations,
    apiUpgradeSubscription,
    apiProlongSubscription,
    apiGetObligation,
    type OrganisationResponse,
    type PostOrganisationBody,
    type DeleteOrganisationBody,
    type SubscriptionResponse,
    type SubscriptionTypeItem,
    type ObligationResponse,
    type PostUpgradeBody,
    type PostProlongBody,
} from '@/api/organisation'
type FullOrganisationResponse = OrganisationResponse & { openInvoices?: boolean | null | undefined }

import { useAuthorisation } from '@/composables/authorisation'
const { canRead } = useAuthorisation()

import { type Organisation, type Collaboration } from '@api/_apiTypes'
// import handleMessages from '@/utilities/handleMessages'
import { useLocalStorage } from '@vueuse/core'
import refreshProgress from '@/utilities/refreshProgress' // loader utilities

export const useOrganisationStore = defineStore('organisation', () => {
    const organisation = useLocalStorage('organisation', ref<FullOrganisationResponse>({}), { mergeDefaults: true })
    const subscription = useLocalStorage('subscription', ref<SubscriptionResponse>())
    const subscriptionTypes = useLocalStorage('subscriptionTypes', ref<SubscriptionTypeItem[]>([]))
    const obligation = useLocalStorage('subscription', ref<ObligationResponse>())

    const collaborations = ref<Collaboration[]>([])

    const toCollaborations = computed(() => {
        return collaborations.value.filter((collab) => {
            // filter out the organisation that is the same as the current organisation
            return collab.partner_organisation_id === organisation.value.id
        })
    })

    const permissions = computed(() => (feature: string) => {
        return (subscription.value?.subscriptiontype as any)?.featureFlags?.includes(feature) ?? false
    })

    async function refreshStore() {
        await getOrganisation()
        await getCollaborations()
        await getSubscription()
        await getObligation()
    }
    onMounted(() => {
        const _refreshOrg = refreshProgress.registerRefreshFunction('org')
        _refreshOrg('organisation', getOrganisation)
        _refreshOrg('collaborations', getCollaborations)
        _refreshOrg('subscription', getSubscription)
        _refreshOrg('obligation', getObligation)
    })

    async function getCollaborations() {
        const _res = await apiGetCollaborations()
        if (!_res) return
        collaborations.value = _res.items as Collaboration[]
    }

    async function getOrganisation() {
        const response = await apiGetOrganisation()
        if (response) {
            organisation.value = response as Organisation
        }
    }

    async function updateOrganisation(body: PostOrganisationBody) {
        try {
            const OrganisationResponse = await apiUpdateOrganisation(body)
            if (OrganisationResponse) {
                organisation.value = OrganisationResponse
                // handleMessages.setSuccess('messages.success')
            }
        } catch (error: any) {
            // handleMessages.setError('messages.success')
        }
    }

    async function upgradeSubscription(body: PostUpgradeBody) {
        try {
            const res = (await apiUpgradeSubscription(body)) as any
            return res
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async function prolongSubscription(body: PostProlongBody) {
        try {
            const res = await apiProlongSubscription(body)
            if (res && subscription.value) {
                subscription.value.prolongInd = res.prolongInd
            }
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async function deleteOrganisation(body: DeleteOrganisationBody) {
        try {
            await apiDeleteOrganisation(body)
            return true
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async function getSubscription() {
        if (!canRead.value('obligation')) return
        refreshProgress.setLoading('Subscription')
        const subscriptionResponse = await apiGetSubscription()
        if (subscriptionResponse) {
            subscription.value = subscriptionResponse
        }
    }

    async function getSubscriptionTypes() {
        if (!canRead.value('obligation')) return
        refreshProgress.setLoading('Subscription')
        const subscriptionTypesResponse = await apiGetSubscriptionTypes()
        if (subscriptionTypesResponse.items) {
            subscriptionTypes.value = subscriptionTypesResponse.items
        }
    }

    async function getObligation() {
        if (!canRead.value('obligation')) return
        refreshProgress.setLoading('Obligation')
        const obligationResponse = await apiGetObligation()
        if (obligationResponse) {
            obligation.value = obligationResponse
        }
    }

    return {
        organisation,
        subscription,
        subscriptionTypes,
        obligation,
        refreshStore,
        getOrganisation,
        updateOrganisation,
        deleteOrganisation,
        getSubscription,
        getSubscriptionTypes,
        upgradeSubscription,
        prolongSubscription,
        getObligation,
        collaborations,
        toCollaborations,
        permissions,
    }
})

import { ref, onMounted, onUnmounted } from 'vue'

const breakpointWidth = 600

export function useScreenWidth() {
    const isMobile = ref(window.innerWidth < breakpointWidth)
    const smallScreen = ref(window.innerWidth < 1280)

    const updateWidth = () => {
        isMobile.value = window.innerWidth < breakpointWidth
        smallScreen.value = window.innerWidth < 1280
    }

    onMounted(() => {
        window.addEventListener('resize', updateWidth)
    })

    onUnmounted(() => {
        window.removeEventListener('resize', updateWidth)
    })

    return {
        isMobile,
        smallScreen,
    }
}
